import React from 'react';

export default function FooterSmall(props) {
  return (
    <>
      <footer
        className={
          props.absolute
            ? 'absolute w-full bottom-0 bg-blueGray-800'
            : 'relative'
        }
        style={{ background: '#000' }}
      >
        <div className="center_container">
          <div className="container px-4">
            {/* <hr style={{ height: 2, borderWidth: 0, color: "black" }} /> */}
            <div className="w-full" style={{ marginTop: 50 }}>
              {/* <div className="row w-full py-1">
                <h4
                  style={{
                    color: "#FFF",
                    margin: "auto auto 25px",
                    fontSize: 16,
                    fontFamily: "Nanum Myeongjo",
                  }}
                >
                  <strong>POWERED</strong> BY
                </h4>
              </div> */}
              {/* <div className="footer_sm_img_wrapper w-full py-1 footer-logo-bottom">
                <div className="hidden md-block"></div>
                <div>
                  <img
                    src="/img/vnx-logo-sm.png"
                    alt="Vaionex"

                  />
                </div>
                <div className="footer_logo-img-2">
                  <img
                    src="/img/bsv-logo-wh-full2.png"
                    alt="Bitcoin SV"

                  />
                </div>
                <div className="staslogo-inner bg-white ">
                 
                  <img
                    src="/img/staslogo.svg"
                    alt="STAS"
                    style={{margin:0}}
                  />
                </div>
                <div className="hidden md-block"></div>


              </div> */}
              <div className="cnw-logo space-x-5">
                <a
                  href="https://www.dmca.com/Protection/Status.aspx?ID=ee7d10b2-bcac-42e1-8fb5-97e5caacc724"
                  rel="noopener noreferrer"
                  title="DMCA.com Protection Status"
                  className="dmca-badge"
                  target="_blank"
                >
                  <img
                    src="https://images.dmca.com/Badges/dmca-badge-w250-1x1-01.png?ID=ee7d10b2-bcac-42e1-8fb5-97e5caacc724"
                    alt="DMCA.com Protection Status"
                  />
                </a>
                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                <a
                  href="https://en.ryte.com/product-insights/website-carbon-neutral-program"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="/img/Ryte-badge.svg"
                    alt="Ryte Carbon Neutral"
                    style={{ marginLeft: 20 }}
                  />
                </a>
              </div>
            </div>
            <div
              className="w-full "
              style={{
                paddingTop: 50,
                fontFamily: 'Roboto, Helvetica, Arial',
              }}
            >
              <div className="text-white py-1 text-center">
                Copyright © Calekta Limited {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
