import { toast } from 'react-toastify';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore/lite';
import { firebaseFireStore } from '../config/firebaseFirestore';
import { firebaseAuthFunc } from '../config/firebaseAuth';

export const showToastMessage = ({
  message = 'Error',
  actionType = 'error',
}) => {
  toast[actionType](message, {
    position: 'bottom-left',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const insertUsername = async (type, docId, userId, newUsername) => {
  if (type === 'add') {
    await addDoc(collection(firebaseFireStore, 'musicartUsernames'), {
      displayName: newUsername,
      userId,
    });
  } else {
    await updateDoc(doc(firebaseFireStore, 'musicartUsernames', docID), {
      displayName: newUsername,
      userId,
    });
  }
};

export const varifyAndInsertUsername = async (
  newUsername,
  showErrorMessage = true,
) => {
  const currentUserId = firebaseAuthFunc?.currentUser?.uid;
  const usernames = await getDocs(
    collection(firebaseFireStore, 'musicartUsernames'),
  );

  let verifyOptions = {
    isVarified: true,
    type: 'add',
    docId: null,
  };

  if (usernames.empty) {
    return verifyOptions;
  }

  for (var i in usernames.docs) {
    const doc = usernames.docs[i];
    const data = doc.data();

    //update the current username if previous and updated is the same or different user name.
    if (
      (data.displayName === newUsername && data.userId === currentUserId) ||
      data.userId === currentUserId
    ) {
      verifyOptions.docId = doc.id;
      verifyOptions.type = 'update';
      break;
    }

    //check if username is already in used.
    if (data.displayName === newUsername && data.userId !== currentUserId) {
      verifyOptions.isVarified = false;
      if (showErrorMessage) {
        showToastMessage({
          message: 'Username already exist, Please use a different one',
          actionType: 'error',
        });
      }
      break;
    }
  }

  if (verifyOptions.isVarified) {
    await insertUsername(
      verifyOptions.type,
      verifyOptions.docId,
      currentUserId,
      newUsername,
    );
  }

  return verifyOptions;
};
