import React, { useMemo, memo, useState } from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { useSelector, useDispatch } from 'react-redux';
import { showUsernameDialog, updateUserDataAction } from '../../store/actions/actionsMain';
import { varifyAndInsertUsername, showToastMessage } from '../../services/insert-username';
import { firebaseAuthFunc } from '../../config/firebaseAuth';
import { updateProfile } from 'firebase/auth';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UsernameDialog() {
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const [userNameField, setUserNameField] = useState('')
    const isUsernameDialog = useSelector((state) => state.authReducer.isUsernameDialog);
    const userDataRedux = useSelector((state) => state.authReducer.userData);

    const handleClose = () => {
        dispatch(showUsernameDialog(false));
    }

    const updateUsername = async () => {
        try {
            setloading(true);
            var updateUser = firebaseAuthFunc.currentUser;
            const data = await varifyAndInsertUsername(userNameField)

            //stop further execution if username already in used 
            if (!data.isVarified) {
                setloading(false);
                return
            }

            await updateProfile(updateUser,{ displayName: userNameField })
            let updatedObj = { ...userDataRedux };
            updatedObj.displayName = userNameField;
            dispatch(updateUserDataAction(updatedObj));
            handleClose() // auto close the username modal
            setloading(false);

            showToastMessage({
                message: "Usernname added successfully",
                actionType: "success"
            })

        } catch (error) {
            setloading(false);
            showToastMessage({
                message: error.message,
                error: 'error'
            })
        }

    }

    if (!isUsernameDialog) return null

    return (
        <Dialog
            open={isUsernameDialog}
            TransitionComponent={Transition}
            keepMounted={false}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            style={{ zIndex: 1000 }}
            maxWidth="sm"
        >
            <div className="dialog-titlebar-medium">
                <div className="dialog-title-medium">
                    Create Your Username
                </div>
                <div className="dialog-close-medium">
                    <button
                        className="bg-white text-black my-2 text-lg font-light h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none border border-gray-300"
                        type="button"
                        onClick={handleClose}
                    >
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <form
                className="bg-blueGray-100"
                onSubmit={(e) => {
                    e.preventDefault()
                    updateUsername();
                }}
            >
                <DialogContent>
                    <div>
                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            UserName
                        </label>
                        <input
                            onChange={(e) => {
                                setUserNameField(e.target.value);
                            }}
                            type="text"
                            defaultValue=""
                            placeholder="Enter your username"
                            required
                            className="font-text border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-lg focus:outline-none focus:ring w-full ease-linear transition-all duration-150 mb-4"
                        />
                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        marginTop: 10,
                        height: 50,
                    }}
                >
                    <div
                        style={{
                            minWidth: 100,
                            textAlign: "center",
                        }}
                    >
                        {loading ?
                            <div className="flex justify-center	w-full">
                                <i className="fas fa-circle-notch animate-spin text-blueGray-800 mx-auto"></i>
                            </div>
                            :
                            <button
                                className="bg-blueGray-700 active:bg-blueGray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                                disabled={loading}
                            >
                                Create
                            </button>

                        }

                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )
}
