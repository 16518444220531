export const CheckUsername = () => {
  // return /^(?=.{6,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9.]+(?<![_.])$/
  return true;
};

export const checkUsername = (username = '', displayName = '') => {
  let obj = {
    error: false,
    errorMessage: '',
  };
  const invalidNames = [
    'admin',
    'info',
    'support',
    'musicart',
    'calekta',
    'peter',
    'dirk',
    'jeff',
    'hugh',
  ];

  if (invalidNames.includes(username.toLowerCase())) {
    obj = {
      error: true,
      errorMessage: `This ${displayName} is not allowed. Try again.`,
    };
  } else if (!/^.{6,30}$/.test(username)) {
    obj = {
      error: true,
      errorMessage: `${displayName} should be between 6-30 characters.`,
    };
  } else if (!/^[A-Za-z0-9.]+$/.test(username)) {
    obj = {
      error: true,
      errorMessage:
        'Only letters(a-z, A-Z), numbers(0-9) and periods (.) are allowed',
    };
  } else if (username.search(/(?![_.])/) > 0 || username.search(/[_.]$/) > 0) {
    obj = {
      error: true,
      errorMessage: `the first or last character of your ${displayName} must be an ascii letter (a-z, A-Z) or number(0-9).`,
    };
  } else if (username.search(/(?!.*[_.]{2})/) > 0) {
    obj = {
      error: true,
      errorMessage: `sorry, your ${displayName} cannot contain consecutive periods (.)`,
    };
  }

  return obj;
};

export const calculateNfts = (multipleNftDetails) => {
  let count = 0;
  // eslint-disable-next-line array-callback-return
  multipleNftDetails &&
    Object.values(multipleNftDetails).map((nft) => {
      if (
        nft.adminState &&
        nft.adminState === 'ACCEPT' &&
        nft.activeInMarketPlace &&
        ((nft.nftSaleType === 'Auctioning' && nft.auctionEndTime) ||
          nft.nftSaleType !== 'Auctioning')
      ) {
        count++;
      }
    });
  return count ? count : 1;
};

export const checkSpotifyLink = (url) => {
  const spotifyRegEx =
    /(?:spotify:|(?:https?:\/\/(?:open|play)\.spotify\.com\/))/;
  return spotifyRegEx.test(String(url));
};

export const checkAppleLink = (url) => {
  const AppleRegEx = /((music)\.apple\.com\/)/;
  return AppleRegEx.test(String(url));
};

/**
 * isEmpty() - is object empty?
 *
 * @param  {Object}  obj
 * @return {Boolean} true/false
 */
export const isEmpty = (obj) => {
  return obj ? Object.keys(obj).length === 0 : true;
};

export const getUserProfileImg = (
  userProfile = {},
  fallBackImg = '/img/unknow.jpg',
) => {
  if (userProfile?.thumbnailUrl?.includes('blob')) {
    return userProfile.thumbnailUrl;
  }

  if (userProfile?.photoURL) {
    return `${userProfile.photoURL}_250x250?alt=media`;
  }

  return fallBackImg;
};
