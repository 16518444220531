import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  IconButton,
  SwipeableDrawer,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';
import Close from '@material-ui/icons/Close';

export default function SideBar(props) {
  const userDataRedux = useSelector((state) => state.authReducer.userData);
  const userLoading = useSelector((state) => state.authReducer.userLoading);
  const router = useRouter();
  const { pathname } = router;
  const [leftPanel, setLeftPanel] = React.useState(false);
  React.useEffect(() => {
    if (leftPanel) {
      setLeftPanel(false);
    }
  }, [pathname]);
  const handleMenu = (event) => {
    setLeftPanel(true);
  };

  const handleClose = () => {
    setLeftPanel(false);
  };
  return (
    <>
      <IconButton
        size="medium"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleMenu}
        style={{
          height: '80%',
          color: '#ff972b',
          padding: '7px',
        }}
      >
        <MenuIcon fontSize="large" style={{ marginRight: '-4px' }} />
      </IconButton>
      <SwipeableDrawer
        anchor={'right'}
        open={leftPanel}
        onClose={handleClose}
        onOpen={handleMenu}
        // classes={{paperAnchorRight:'right'}}
        PaperProps={{
          elevation: 0,
          // className: styles.headermobile,
          style: {
            // right: 35,
            height: 'auto',
            width: 180,
            top: 70,
            color: 'black',
          },
        }}
      >
        <MenuList>
          <Link prefetch={false} href="/">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              Home
            </MenuItem>
          </Link>
          <Link prefetch={false} href="/mintNFT">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/mintNFT'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              Mint
            </MenuItem>
          </Link>
          <Link prefetch={false} href="/discover">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/discover'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              Discover
            </MenuItem>
          </Link>
          <Link prefetch={false} href="/artists">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/artists'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              MusicArtists
            </MenuItem>
          </Link>
          <Link prefetch={false} href="/collectors">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/collectors'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              Collectors
            </MenuItem>
          </Link>
          <Link prefetch={false} href="/about">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/about'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              About
            </MenuItem>
          </Link>
          <Link prefetch={false} href="/blog">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/auth/register'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              Blog
            </MenuItem>
          </Link>

          <Link prefetch={false} href="/resources">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/resources'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              Resources
            </MenuItem>
          </Link>

          <Link prefetch={false} href="/help">
            <MenuItem
              style={{ height: '4.6vh', position: 'relative' }}
              className={
                router.pathname == '/help'
                  ? 'menu-item active_nav_link'
                  : 'menu-item'
              }
            >
              Help
            </MenuItem>
          </Link>

          {!userDataRedux && userLoading === false && (
            <Link prefetch={false} href="/auth/register">
              <MenuItem
                style={{ height: '4.6vh', position: 'relative' }}
                className={
                  router.pathname == '/auth/register'
                    ? 'menu-item active_nav_link'
                    : 'menu-item'
                }
              >
                Sign Up
              </MenuItem>
            </Link>
          )}
        </MenuList>
      </SwipeableDrawer>
    </>
    // Pass on our props
    // <Menu {...props} right>
    //   <div>
    //     <Link prefetch={false} href="/">
    //       <a
    //         className={
    //           router.pathname == "/" ? "menu-item active_nav_link" : "menu-item"
    //         }
    //       >
    //         Home
    //       </a>
    //     </Link>
    //   </div>

    // </Menu>
  );
}
