import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import { useRouter } from 'next/router';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function JoinNewsDialog(props) {
  const router = useRouter();

  const handleClose = () => {
    props.setdialogState(false);
  };
  const handleOK = () => {
    props.setdialogState(false);
    // window.open(
    //   'https://musicart.us6.list-manage.com/subscribe/post?u=1ee9b1b15690d41303699243c&amp;id=e86d20e9d6',
    //   '_blank'
    // );
  };

  return (
    <Dialog
      open={props.dialogState}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 380,
        },
      }}
      disableScrollLock={false}
      style={{ zIndex: 999 }}
      maxWidth="sm"
      onClose={handleClose}
    >
      <div>
        <div className="dialog-titlebar">
          <div className="dialog-close">
            <button
              className="bg-white text-black my-2 text-lg font-light h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none border border-gray-300"
              type="button"
              onClick={handleClose}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div>
          <DialogContent>
            <div>
              <p className="text-black text-base font-medium mb-2 text-center mt-3">
                Thank you for subscribing.
              </p>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              justifyContent: 'center',
              boxSizing: 'border-box',
              textAlign: 'center',
            }}
          >
            <div className="w-full px-4 mb-4">
              <button
                className="dialog__btn py-3 my-2 rounded-full hover:outline-none focus:outline-none ease-linear w-full"
                type="button"
                onClick={handleOK}
              >
                OK
              </button>
            </div>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
}
