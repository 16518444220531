import React, { useEffect, useState } from 'react';
import Modal from 'simple-react-modal';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import JoinNewsDialog from './JoinNewsDialog';

const CustomForm = ({
  status,
  message,
  onValidated,
  setjoinNewsDialogState,
  setOpenModal,
  joinNewsDialogState,
}) => {
  // console.log(status, "jhsfhds");
  const [modalOpen, setModalOpen] = useState();

  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    // console.log(status, "jjjjjjjjjjjjjjjjjjjjjjjj");
    e.preventDefault();
    email &&
      email.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === 'success') clearFields();
    if (modalOpen && status === 'success') clearFields();
  }, [status, modalOpen]);

  const clearFields = () => {
    setEmail('');
  };

  const handleEmail = () => {
    // setjoinNewsDialogState(true);
    // setOpenModal(false);
    // if (status === "success") {
    //   console.log("heyey")
    // }
  };
  console.log(status === 'success', status);
  return (
    <form
      action="https://musicart.us6.list-manage.com/subscribe/post?u=1ee9b1b15690d41303699243c&id=e86d20e9d6"
      className="mc__form"
      onSubmit={handleSubmit}
    >
      {status === 'sending' && (
        <div className="mc__alert mc__alert--sending">sending...</div>
      )}
      {status === 'error' && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <JoinNewsDialog
          dialogState={joinNewsDialogState}
          setdialogState={setjoinNewsDialogState}
        />
      )}

      {status !== 'success' ? (
        <div className="flex justify-center">
          <input
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="email"
            value={email}
            isRequired
            placeholder="Enter your Email here..."
            className="footer-newsletter-pop"
          />

          {status === 'success' ? (
            <button
              handleClick={() => setModalOpen(false)}
              label="close"
              size="big"
              customClassName="g__justify-self-center"
            />
          ) : (
            <button
              type="submit"
              style={{ color: '#fff', background: '#F16604', padding: '18px' }}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          )}
        </div>
      ) : null}

      <div
        style={{
          fontSize: '13px',
          color: '#fff',
          textAlign: 'center',
          marginTop: '32px',
        }}
      >
        You can {status === 'success' ? `unsubscribe` : 'subscribe'} at any time
        by clicking the link in the footer of our emails. <br />
        We use Mailchimp as our marketing platform. By clicking below to
        subscribe, you acknowledge that your information will be transferred to
        Mailchimp for processing.
        <a
          href="https://mailchimp.com/legal/"
          className="link-hovering"
          style={{ color: '#333' }}
          rel="noreferrer"
          target="_blank"
        >
          Learn more about Mailchimp's privacy practices here.
        </a>
      </div>
    </form>
  );
};
export default function Footer() {
  const [showCookiesAlert, setShowCookiesAlert] = useState(true);
  const hideCookiesAlert = () => setShowCookiesAlert(false);
  const [joinNewsDialogState, setjoinNewsDialogState] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const display = showCookiesAlert ? 'block' : 'none';
  const statusHandle = () => {};
  // const url = `https://jster.us7.list-manage.com/subscribe/post?u=ed40c0084a0c5ba31b3365d65&id=ec6f32bf5e`;
  const url =
    'https://musicart.us6.list-manage.com/subscribe/post?u=1ee9b1b15690d41303699243c&id=e86d20e9d6';

  return (
    <>
      <footer className="relative footer__bg center_container">
        <div
          className="container mx-auto px-4"
          style={{ marginTop: 65, marginBottom: 40 }}
        >
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-3/12 px-10" style={{ marginTop: 15 }}>
              <a style={{ cursor: 'pointer', marginTop: 15 }} href="/">
                <img
                  className="footer_mark"
                  src="/img/MusicArtFooter.png"
                  alt=""
                  style={{ width: '80%' }}
                />
              </a>
            </div>

            <div className="w-full lg:w-9/12">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-3/12 px-4 items-top1">
                  {/* <span className="footer__title">Legal</span> */}
                  <ul className="list-unstyled resp-footer">
                    <li className="footer__links">
                      <a href="/terms-of-service" target="_self">
                        Terms of Service
                      </a>
                    </li>
                    <li className="footer__links">
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>

                    <li className="footer__links">
                      <a href="/auction-rules">Auction Rules</a>
                    </li>
                    {/* <li className="footer__links">
                      <a href="/collection">My collection</a>
                    </li> */}
                  </ul>
                </div>
                <div className="w-full lg:w-3/12 px-4 items-top2">
                  <ul className="list-unstyled">
                    <li className="footer__links">
                      <a
                        style={{
                          color: 'white',
                          fontSize: '0.875rem',
                          fontWeight: '600',
                          cursor: 'default',
                        }}
                      >
                        Info
                      </a>
                    </li>
                    <li className="footer__links">
                      <a href="/about">About</a>
                    </li>
                    <li className="footer__links">
                      <a href="/help">Help</a>
                    </li>
                    <li className="footer__links">
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-6/12">
                  <button
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    className="footer__title newer-join-btn"
                  >
                    <span>Join Newsletter</span>
                  </button>
                  {/* <div className="footer__email my-4"> */}
                  {/* <form
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="validate"
                      noValidate
                    >
                      <div
                        className="input-group"
                        style={{ display: "flex", position: "relative" }}
                      >
                        <input
                          type="email"
                          name="EMAIL"
                          className="form-control form-control-light"
                          id="mce-EMAIL"
                          placeholder="Enter your Email here..."
                        />
                        <button
                          style={{
                            marginTop: "5px",
                            position: "absolute",
                            marginRight: "-5px",
                          }}
                          onClick={() => {
                            setjoinNewsDialogState(true)
                          }}
                          type="submit"
                          className="btn btn-color"
                        >
                          {" "}
                          <svg
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            fillRule="evenodd"
                            clipRule="evenodd"
                          >
                            <path
                              fill="#FFF"
                              d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z"
                            />
                          </svg>
                        </button>
                      </div>
                      <div id="mce-responses" className="clear">
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: "none" }}
                        ></div>
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                      {/* <div
                        style={{
                          position: "absolute",
                          left: 5000,
                        }}
                        aria-hidden="true"
                      >
                        <input
                          type="text"
                          name="b_1ee9b1b15690d41303699243c_e86d20e9d6"
                          tabindex="-1"
                          value=""
                        />
                      </div> */}
                  {/* </form> */}
                  {/* </div> */}

                  <div className="w-full about_links" style={{ marginTop: 25 }}>
                    <a
                      href="https://www.facebook.com/musicart.nft"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://twitter.com/MusicArt_nft"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/MusicArt.nft"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <JoinNewsDialog
        dialogState={joinNewsDialogState}
        setdialogState={setjoinNewsDialogState}
      />

      <Modal
        style={{ background: 'rgb(0 0 0 / 64%)' }} //overwrites the default background
        containerStyle={{
          background: '#FF9700',
          width: '70%',
          height: '469px',
          boxShadow: '0px 0px 27px black',
          margin: '5% auto',
          padding: '22px 20px 13px',
        }} //changes styling on the inner content area
        containerClassName="test"
        closeOnOuterClick={true}
        show={openModal}
        onClose={() => setOpenModal(false)}
      >
        <span
          href="/"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            color: 'white',
            marginTop: '10px',
          }}
          onClick={() => setOpenModal(false)}
        >
          <span className="cursor-pointer">X</span>
        </span>
        <div>
          <div className="wrap-line border-dark">
            <h3>
              <span className="font-weight-200">Stay</span> Tuned
            </h3>
          </div>
          <div>
            <h2 className="join-footer">Join our Newsletter </h2>
          </div>
          <MailchimpSubscribe
            url={
              'https://musicart.us6.list-manage.com/subscribe/post?u=1ee9b1b15690d41303699243c&id=e86d20e9d6'
            }
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
                setjoinNewsDialogState={setjoinNewsDialogState}
                setOpenModal={setOpenModal}
              />
            )}
          />
          {/* <div className="flex justify-center">
            <input
              type="Email"
              placeholder="Enter your Email here..."
              className="footer-newsletter-pop"
            />

            <button
              style={{ color: "#fff", background: "#F16604", padding: "18px" }}
              onClick={() => {
                setjoinNewsDialogState(true);
                setOpenModal(false);
              }}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div> */}
        </div>
      </Modal>
      {/* <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script> */}
    </>
  );
}
