import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Menu, Dropdown } from 'antd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  destroyWalletData,
  updateUserDataAction,
  updateUserFollowingArtistsAction,
} from '../../store/actions/actionsMain';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CollectionsIcon from '@material-ui/icons/Collections';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SideMenu from '../SideMenu/SideMenu';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import UsernameDialog from '../UsernameDialog';
import { signOut } from 'firebase/auth';
import { firebaseAuthFunc } from '../../config/firebaseAuth';
import { doc, getDoc, query, where } from 'firebase/firestore/lite';
import { firebaseFireStore } from '../../config/firebaseFirestore';
import { getUserProfileImg } from '../../helpers/regex';
import { getDocs, collection } from 'firebase/firestore/lite';
import CloseIcon from '@material-ui/icons/Close';
import {
  updateHandCashProfile,
  updateHandCashBalance,
} from '../../store/actions/actionsMain';

export default function Navbar(props) {
  const userDataRedux = useSelector((state) => state.authReducer.userData);
  const userLoading = useSelector((state) => state.authReducer.userLoading);

  const dispatch = useDispatch();
  const router = useRouter();
  const uid = router.query;
  const [isAdmin, setisAdmin] = useState(false);
  const [userImage, setUserImage] = useState('/img/avatars/avatar.jpg');

  const [toogle, setToogle] = useState(false);

  const [loadAvater, setLoadAvater] = useState(true);

  const [notification, setNotification] = useState([]);
  const [closeNotification, setCloseNotification] = useState(false);

  const getNotification = async () => {
    let dataArr = [];
    let musicartNftsRef = query(
      collection(firebaseFireStore, 'NotificationCenter'),
      where('isActive', '==', true),
    );
    let dataRef = await getDocs(musicartNftsRef);

    // const musicartNftsRef = await getDocs(
    //   collection(firebaseFireStore, 'NotificationCenter'),
    // );
    dataRef.forEach((data, index) => {
      // dataArr.push({ ...data.data(), token: musicartNftsRef.docs[index].id });
      dataArr.push({ ...data.data() });
    });
    setNotification(dataArr);
    setCloseNotification(true);
  };
  useEffect(async () => {
    getNotification();
  }, [router.pathname]);

  useEffect(() => {
    if (userDataRedux) {
      setUserImage(getUserProfileImg(userDataRedux, '/img/avatars/avatar.jpg'));

      getDoc(doc(firebaseFireStore, 'admin', 'adminList')).then((doc) => {
        if (
          doc.exists &&
          doc.data() &&
          doc.data()?.admins?.includes(userDataRedux.uid)
        ) {
          //user is admin
          setisAdmin(true);
        }
      });
    }
  }, [userDataRedux]);

  useEffect(() => {
    setTimeout(() => setLoadAvater(false), 1500);
  }, []);

  const logout = () => {
    signOut(firebaseAuthFunc).then(() => {
      // Sign-out successful.
      dispatch(updateUserDataAction(null));
      dispatch(updateUserFollowingArtistsAction([]));
      dispatch(destroyWalletData(null));

      dispatch(updateHandCashProfile(null));
      dispatch(updateHandCashBalance(null));

      toast.success('You have successfully signed out.', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      router.push('/');
    });
  };

  const [dropDownProps, setDropDownProps] = useState('hover');
  const isSmallerScreen = 768;

  const [size, setSize] = useState(0);

  const handleDropDownProps = () => {
    if (size > isSmallerScreen) {
      setDropDownProps('hover');
    } else {
      setDropDownProps('click');
    }
  };
  useEffect(() => {
    handleSize();

    window.addEventListener('resize', handleSize);
    handleDropDownProps();
  }, [size]);

  const handleSize = () => {
    setSize(window.innerWidth);
  };

  const menuDropdown = (
    <Menu
      className={
        toogle ? 'text-blueGray-700 hidden' : 'text-blueGray-700 block'
      }
      style={{ minWidth: 140 }}
    >
      <Menu.Item className="py-2">
        <Link prefetch={false} href="/wallet" rel="canonical">
          <a>
            <div className="flex items-center	">
              <img
                src="/img/icons/wallet-icon.svg"
                style={{ width: 22, height: 22 }}
              />
              <p className="ml-3">
                <span className="font-normal block">Wallet</span>
              </p>
            </div>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item className="py-2">
        <Link prefetch={false} href="/collection" rel="canonical">
          <a>
            <div className="flex items-center	">
              <CollectionsIcon className="inline-block" />
              <p className="ml-3">
                <span className="font-normal block">My Collection</span>
              </p>
            </div>
          </a>
        </Link>
      </Menu.Item>
      {userDataRedux && userDataRedux.uid && (
        <Menu.Item className="py-2">
          <Link
            prefetch={false}
            href={'/collector/' + userDataRedux.uid}
            rel="canonical"
          >
            <a>
              <div className="flex items-center	">
                <RecentActorsIcon className="inline-block" />
                <p className="ml-3">
                  <span className="font-normal block">Collector Profile</span>
                </p>
              </div>
            </a>
          </Link>
        </Menu.Item>
      )}

      {isAdmin && (
        <Menu.Item className="py-2">
          <Link prefetch={false} href="/admin" rel="canonical">
            <a>
              <div className="flex items-center	">
                <SupervisorAccountIcon className="inline-block" />
                <p className="ml-3">
                  <span className="font-normal block">Admin Panel</span>
                </p>
              </div>
            </a>
          </Link>
        </Menu.Item>
      )}

      <Menu.Item className="py-2" onClick={logout}>
        <div className="flex items-center	">
          <ExitToAppIcon className="inline-block" />
          <p className="ml-3">
            <span className="font-normal block">Log out</span>
          </p>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {closeNotification == true && !router.pathname.includes('admin') ? (
        <>
          {notification &&
            notification
              .filter((x) => x.isActive)
              .splice(0, 1)
              .map((y) => (
                <div
                  className="items-center text-black flex max-h-[70px] top-0 py-3 fle-wrap justify-between left-0 sticky w-full"
                  style={{
                    zIndex: '1500',
                    background: '#F16604',
                    height: '3.5rem',
                  }}
                >
                  <div className=" mx-auto p-0 text-center ymessage ">
                    <div className="flex items-center justify-center h-full text-xs sm:text-lg text-white">
                      <p className="px-8 sm:px-10">{y.message}</p>
                      <CloseIcon
                        className="cursor-pointer close_icon_notification"
                        onClick={(e) => setCloseNotification(false)}
                      />
                    </div>
                  </div>
                </div>
              ))}
        </>
      ) : (
        ''
      )}
      <UsernameDialog />
      <header
        className={`header top-0 ${
          closeNotification === false
            ? 'header center_container top-0'
            : notification.length > 0
            ? 'header center_container top-11'
            : ''
        }`}
      >
        <div
          className="container"
          style={{ padding: 0, position: 'relative', zIndex: 1500 }}
        >
          <div className="header__content px-0 sm:px-4 md:px-4 lg:px-4 xl:px-4">
            <div className="header__logo">
              <div className="text-title text-sm font-bold leading-relaxed inline-block py-2 whitespace-nowrap flex">
                <div className="header__image">
                  <source
                    media="(max-width: 799px)"
                    srcSet="/img/brand/Logo_icon.png"
                    onClick={() => router.push('/')}
                    className="cursor-pointer"
                  />
                  <source
                    media="(min-width: 800px)"
                    srcSet="/img/brand/Logo1.png"
                    onClick={() => router.push('/')}
                    className="cursor-pointer"
                  />
                  <img
                    onClick={() => router.push('/')}
                    src="/img/brand/Logo1.png"
                    alt="MusicArt"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>

            <div className="header__menu">
              <ul className="header__nav">
                <li className="header__nav-item">
                  <Link prefetch={false} href="/" rel="canonical">
                    <a
                      className={
                        router.pathname == '/'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      Home
                    </a>
                  </Link>
                </li>
                <li className="header__nav-item">
                  <Link prefetch={false} href="/mintNFT" rel="canonical">
                    <a
                      className={
                        router.pathname == '/mintNFT'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      Mint
                    </a>
                  </Link>
                </li>

                <li className="header__nav-item">
                  <Link prefetch={false} href="/discover" rel="canonical">
                    <a
                      className={
                        router.pathname == '/discover'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      Discover
                    </a>
                  </Link>
                </li>
                <li className="header__nav-item">
                  <Link prefetch={false} href="/artists" rel="canonical">
                    <a
                      className={
                        router.pathname == '/artists'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      MusicArtists
                    </a>
                  </Link>
                </li>

                <li className="header__nav-item">
                  <Link prefetch={false} href="/collectors" rel="canonical">
                    <a
                      className={
                        router.pathname == '/collectors'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      Collectors
                    </a>
                  </Link>
                </li>

                <li className="header__nav-item">
                  <Link prefetch={false} href="/about" rel="canonical">
                    <a
                      className={
                        router.pathname == '/about'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      About
                    </a>
                  </Link>
                </li>
                <li className="header__nav-item">
                  <Link prefetch={false} href="/blog" rel="canonical">
                    <a
                      className={
                        router.pathname == '/blog'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      Blog
                    </a>
                  </Link>
                </li>
                <li className="header__nav-item">
                  <Link prefetch={false} href="/resources" rel="canonical">
                    <a
                      className={
                        router.pathname == '/resources'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      Resources
                    </a>
                  </Link>
                </li>
                <li className="header__nav-item">
                  <Link prefetch={false} href="/help" rel="canonical">
                    <a
                      className={
                        router.pathname == '/help'
                          ? 'header__nav-link active_nav_link'
                          : 'header__nav-link'
                      }
                    >
                      Help
                    </a>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="header__actions">
              <div className="header__action header__action--profile">
                {userLoading === true && (
                  <>
                    <li className="items-center justify-center flex">
                      <i className="fas fa-circle-notch animate-spin text-blueGray-800 mx-auto"></i>
                    </li>
                  </>
                )}
                {!userDataRedux && userLoading === false && (
                  <div className="flex items-center space-x-3">
                    <li
                      className="items-center flex sign_up_button mr-3
                    "
                    >
                      <Link
                        prefetch={false}
                        href={
                          uid.nftId
                            ? `/auth/register?nftId=${uid.nftId}`
                            : '/auth/register'
                        }
                        rel="canonical"
                      >
                        <a className="header__action-btn header__action-btn--signin">
                          <span>Sign Up</span>
                        </a>
                      </Link>
                    </li>

                    <li className="items-center flex">
                      <Link
                        prefetch={false}
                        href={
                          uid.nftId
                            ? `/auth/login?nftId=${uid.nftId}`
                            : '/auth/login'
                        }
                        rel="canonical"
                      >
                        <a className="header__action-btn header__action-btn--signin">
                          <span>Sign In</span>
                        </a>
                      </Link>
                    </li>
                  </div>
                )}

                {userDataRedux && userLoading === false && (
                  <>
                    <li className="items-center flex ">
                      <Dropdown
                        overlay={menuDropdown}
                        placement="bottomCenter"
                        arrow
                        // trigger={['click']}
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <div className="text-title flex text-xs font-bold px-4 py-2 lg:hover:text-hover-link cursor-pointer header__profile-btn">
                            {userDataRedux.photoURL && (
                              <img
                                className="profile_icon inline-block h-8 w-8 ring-2 ring-white mr-2"
                                onError={(e) =>
                                  setUserImage('/img/avatars/avatar.jpg')
                                }
                                src={userImage}
                                alt=""
                              />
                            )}

                            <span className="displayname">
                              {userDataRedux.displayName &&
                              userDataRedux.displayName.length > 30
                                ? userDataRedux.displayName.substring(0, 30) +
                                  '...'
                                : userDataRedux.displayName}
                            </span>
                          </div>
                          <ExpandMoreIcon
                            style={{ fontSize: 15, marginLeft: 4 }}
                          />
                        </a>
                      </Dropdown>
                    </li>
                    <li className="flex items-center hidden">
                      <Link prefetch={false} href="/wallet" rel="canonical">
                        <a className="text-blueGray-700 px-3 py-3 lg:py-2 flex items-center text-xs uppercase font-bold">
                          <img
                            className="mr-2"
                            src="/img/icons/wallet-icon.svg"
                            style={{ width: 22, height: 22 }}
                          />
                          Wallet
                        </a>
                      </Link>
                    </li>

                    <li className="flex items-center hidden">
                      <Link prefetch={false} href="/collection" rel="canonical">
                        <a className="text-blueGray-700 px-3 py-3 lg:py-2 flex items-center text-xs uppercase font-bold">
                          <CollectionsIcon className="mr-2" />
                          My Collection
                        </a>
                      </Link>
                    </li>

                    {userDataRedux && userDataRedux.uid && (
                      <li className="flex items-center hidden">
                        <Link
                          prefetch={false}
                          href={'/collector/' + userDataRedux.uid}
                          rel="canonical"
                        >
                          <a className="text-blueGray-700 px-3 py-3 lg:py-2 flex items-center text-xs uppercase font-bold">
                            <RecentActorsIcon className="mr-2" />
                            Seller Profile
                          </a>
                        </Link>
                      </li>
                    )}
                    {isAdmin && (
                      <li className="flex items-center hidden">
                        <Link prefetch={false} href="/admin" rel="canonical">
                          <a className="text-blueGray-700 px-3 py-3 lg:py-2 flex items-center text-xs uppercase font-bold">
                            <SupervisorAccountIcon className="mr-2" />
                            Admin Panel
                          </a>
                        </Link>
                      </li>
                    )}
                    <li className="flex items-center hidden">
                      <a
                        onClick={logout}
                        className="text-blueGray-700 px-3 py-3 lg:py-2 flex items-center text-xs uppercase font-bold"
                      >
                        <ExitToAppIcon className="mr-2" />
                        Log out
                      </a>
                    </li>
                  </>
                )}
              </div>
              <div className="header__action hamburger__menu">
                <SideMenu pageWrapId={'page-wrap'} />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
